<template>
	<div id="box">
		<div class="box">
			<div class="top">
				<div class="topleft">
					<div class="Taxrate">
						<h1>电产比<span class="dsb">电税比</span></h1>
						<div class="Taxratebox">
							<div class="Taxitem">
								<img src="@/assets/img/折线图.png" />
								<div>
									<p>{{energyProduction}}<span>元/千瓦时</span></p>
									<p>(产值/电量)</p>
								</div>
							</div>
							<div class="Taxitem">
								<img src="@/assets/img/柱状图.png" />
								<div>
									<p>{{energyTax}}<span>元/千瓦时</span></p>
									<p>(税收/电量)</p>
								</div>
							</div>
						</div>
					</div>
					<div class="czanalyse">
						<cabar></cabar>
					</div>
				</div>

				<div class="energy">
					<energytab></energytab>
				</div>
			</div>
			<div class="btm">
				<!-- <div class="btmleft">
					<publicbar :name="'亩均论英雄'"></publicbar>
				</div>
				<div class="btmright">
					<publicbar :name="'低效整治'"></publicbar>
				</div> -->
			<operationbox></operationbox>
			</div>
		</div>
	</div>
</template>

<script>
	import energytab from "@/components/operationalanalysis/energytab.vue"
	import cabar from "@/components/operationalanalysis/czanalysebar"
	import operationbox from "@/components/operationalanalysis/operationbox"
	// import publicbar from "@/components/operationalanalysis/publicbar"
	import {ratio} from "@/api/operationalanalysis/operationalanalysis"
	export default {
    data() {
      return {
        energyTax:0.55,
        energyProduction:13.70
      }
    },
		components:{
			energytab,
			cabar,
      operationbox
			// publicbar
		}
		,
    mounted() {
      this.getRatio()
    },
    methods:{
      getRatio(){
        let _this = this
		ratio().then(res=> {
          //console.log(res)
          if(res.data.data){
            //_this.energyTax = res.data.data.energyTax
            //_this.energyProduction = res.data.data.energyProduction
          }
        })
      },
    }
  }
</script>

<style lang="less" scoped="scoped">
	.box{
		width: 100%;
		min-height:600px ;
		background-color: #f2f3f5;
		border-radius: 4px;
	}
	.top{
		width: 100%;
		height: 49%;
		display: flex;
		flex-wrap: wrap;
	}
	.Taxrate{
		width: 100%;
		height: 145px;
		background-color: #FFFFFF;
		border-radius: 4px;
		padding: 4px;
	}
	h1{
		font-size: 18px;
		margin: 0;
    padding-left:120px ;
	}
  .dsb{
    padding-right:120px ;
    float: right;
  }
	.czanalyse{
		width: 100%;
		height: 260px;
		margin-top: 8px;
	}
	.topleft{
		width: 49%;
		height: 412px;
		border-radius: 4px;
	}
	.Taxratebox{
		width: 100%;
		display: flex;
		height: 86px;
		align-items: center;
		justify-content: space-around;
		margin-top: 20px;
		.Taxitem{
			width: 186px;
			height: 86px;
			display: flex;
			align-items: center;
			color: #FFFFFF;
			border-radius: 4px;
			img{
				width: 62px;
				height: 56px;
			}
			background-color: #0040D0;
		}
		.Taxitem>div>p:nth-child(1){
			font-size: 18px;
			font-weight: bold;
			margin-bottom: 5px;
			span{
				font-size: 16px;
				font-weight: normal;
			}
		}
		.Taxitem>div>p:nth-child(2){
			font-size: 14px;
		}
		.Taxitem:nth-child(1){
			background-image: -webkit-linear-gradient(left,#01E1FC,#01E1FC,#0093E9,#0093E9,#0093E9);
			background-image: -o-linear-gradient(right,#01E1FC,#01E1FC,#0093E9,#0093E9,#0093E9);
			background-image: -moz-linear-gradient(right,#01E1FC,#01E1FC,#0093E9,#0093E9,#0093E9);
			background-image: linear-gradient(to right,#01E1FC,#01E1FC,#0093E9,#0093E9,#0093E9);
		}
		.Taxitem:nth-child(2){
			background-image: -webkit-linear-gradient(left,#FC8988,#FC8988,#F85F85,#F85F85,#F85F85);
			background-image: -o-linear-gradient(right,#FC8988,#FC8988,#F85F85,#F85F85,#F85F85);
			background-image: -moz-linear-gradient(right,#FC8988,#FC8988,#F85F85,#F85F85,#F85F85);
			background-image: linear-gradient(to right,#FC8988,#FC8988,#F85F85,#F85F85,#F85F85);
		}
	}
	.energy{
		width: 49%;
		height: 412px;
		margin-left: auto;
		border-radius: 4px;
	}

	.btm{
		width: 100%;
		height: 400px;
		border-radius: 4px;
		margin-top: 8px;
		display: flex;
		align-items: center;
		.btmleft{
			width: 49%;
			height: 100%;
			border-radius: 4px;
		}
		.btmright{
			width: 49%;
			height: 100%;
			border-radius: 4px;
			margin-left: auto;
		}
	}
</style>
