<template>
  <div class="boxx">
    <div class="top">
      <h1>单位能耗增加值</h1>
      <!-- <el-button-group>
        <el-button :type="activeid==1?'primary':''" @click="activetype(1)">片区</el-button>
        <el-button :type="activeid==2?'primary':''" @click="activetype(2)">园区</el-button>
        <el-button :type="activeid==3?'primary':''" @click="activetype(3)">企业</el-button>
      </el-button-group> -->
    </div>
    <el-table :data="tableData" stripe max-height="315px" border :header-cell-style="{
				'text-align':'center',
				'font-size':'16px',
				'color':'#333'
			}" :cell-style="{'text-align':'center'}" style="width: 100%" v-if="activeid=='3'">
      <el-table-column prop="tenantName" label="企业名称" width="200">
      </el-table-column>
      <el-table-column align="center" prop="unitEnergyAdded" label="能耗(万元/吨标煤)" width="200">
      </el-table-column>
      <el-table-column align="center" prop="range" label="环比">
      </el-table-column>
      <el-table-column align="center" prop="rank" label="排名">
      </el-table-column>
    </el-table>
    <div class="pagin" v-if="activeid=='3'">
      <el-button plain>导出</el-button>
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="page"
        :page-sizes="[100, 200, 300, 400]" :page-size="limit" layout=" prev, pager, next, jumper,total" :total="count">
      </el-pagination>
    </div>
    <div class="bar" v-if="activeid=='1'||activeid=='2'">
      <div ref="czbar" class="czbar"></div>
    </div>

  </div>
</template>

<script>
	import {unitEnergy} from "@/api/operationalanalysis/operationalanalysis"
  export default {
    data() {
      return {
        tableData: [{
            name: "新双",
            energy: "0.23",
            range: "0.1",
            rank: "1"
          },
          {
            name: "金田",
            energy: "0.56",
            range: "0.2",
            rank: "2"
          },
          {
            name: "华鸿",
            energy: "0.23",
            range: "0.1",
            rank: "3"
          },
          {
            name: "天成",
            energy: "0.23",
            range: "0.1",
            rank: "4"
          },
          {
            name: "彩虹",
            energy: "0.23",
            range: "0.1",
            rank: "5"
          },
          {
            name: "曙光",
            energy: "0.23",
            range: "0.1",
            rank: "6"
          },
          {
            name: "黄河",
            energy: "0.23",
            range: "0.1",
            rank: "7"
          },
          {
            name: "温龙",
            energy: "0.23",
            range: "0.1",
            rank: "8"
          },
        ],
        page: 1,
        limit: 10,
        count:0,
        activeid: 3,
        xAxis: [],
        yAxis:[]
      }
    },
    mounted() {
      this.activetype(this.activeid)
    },
    methods: {
      // chart(box) {
      //   var myChart = this.$echarts.init(box);
      //   var option;
      //   var colors = ['#5470C6'];
      //   option = {
      //     color: colors,
      //     title:{
      //       subtext: '万元'
      //     },
      //     tooltip: {
      //       trigger: 'axis',
      //       axisPointer: {
      //         type: 'cross'
      //       }
      //     },

      //     grid: {
      //       right: '5%'
      //     },
      //     legend: {
      //       data: ['能耗'],
      //       width: "17%",
      //       right: "1%"
      //     },
      //     xAxis: [{
      //       type: 'category',
      //       axisTick: {
      //         alignWithLabel: true
      //       },
      //       axisLabel: {
      //         interval:0,
      //         rotate:40
      //       },
      //       data: this.xAxis
      //     }],
      //     yAxis: [
      //       {
      //         position: 'left',
      //         axisLabel: {
      //           formatter: '{value} '
      //         },
      //         splitLine: {
      //           show: false
      //         }
      //       }
      //     ],
      //     dataZoom:[
      //       {
      //         type:'slider',
      //         xAxisIndex:0,
      //         bottom: 0,
      //         height:12,
      //         showDetail: false,
      //         startValue: 0,
      //         endValue:8,
      //         handleSize: '110%',
      //         handleStyle:{
      //           color:"#d3dee5",
      //         },
      //         textStyle:{
      //           color:"#fff"
      //         },
      //         borderColor:"#90979c"
      //       },
      //     ],
      //     series: [{
      //         name: '能耗',
      //         type: 'bar',
      //         data: this.yAxis,
      //         barWidth: 17,
      //         label: {
      //           show: true,
      //           position: "top"
      //         },
      //         itemStyle: {
      //           normal: {
      //             color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
      //                 offset: 0,
      //                 color: 'rgba(194,144,19,0.75)'
      //               },
      //               {
      //                 offset: 0.25,
      //                 color: 'rgba(185,203,25,0.67)'
      //               },
      //               {
      //                 offset: 0.5,
      //                 color: 'rgba(102,214,33,0.57)'
      //               },
      //               {
      //                 offset: 0.75,
      //                 color: 'rgba(53,226,41,0.47)'
      //               },
      //               {
      //                 offset: 1,
      //                 color: 'rgba(47,235,154,0.4)'
      //               }
      //             ]),
      //           }
      //         }
      //       }
      //     ]
      //   };
      //   option && myChart.setOption(option);
      // },
      activetype(val) {
        this.activeid = val
        if(val==1||val==2){
          this.getUnitEnergy(val)
        }else {
          this.getUnitEnergy(val)
        }
      },
      getUnitEnergy(val){
        let _this = this
        let parms ={type:val}
        if(val == 3){
           parms ={type:val,limit: _this.limit,page: _this.page}
        }
		unitEnergy(val,_this.limit,_this.page).then(res=> {
          //console.log(res.data.data)
          if(res.data.data.list){
            _this.xAxis=[];
            _this.yAxis=[];
            if(val==1||val==2){
              res.data.data.list.forEach(item=>{
                if(val==1){
                  _this.xAxis.push(item.industrialAreaName)
                }
                if(val==2){
                  _this.xAxis.push(item.projectName)
                }
                _this.yAxis.push(item.unitEnergyAdded.toFixed(2))
              })

            }else {
              _this.tableData =  res.data.data.list
              _this.count = res.data.data.count
              _this.tableData.forEach((item,index)=>{
                // _this.tableData[index].rank=(_this.page-1)*10+index+1
                _this.tableData[index].range=item.ringRatio.toFixed(2)
                _this.tableData[index].rank=(_this.page-1)*10+index+1
              })
            }

          }
          setTimeout(()=>{
            var box= this.$refs.czbar
            // this.chart(box)
          },200)
        })
      },
      handleSizeChange(val) {
        this.limit=val
        this.getUnitEnergy(this.activeid)
        console.log(`每页 ${val} 条`);
      },
      handleCurrentChange(val) {
        this.page=val
        this.getUnitEnergy(this.activeid)
        console.log(`当前页: ${val}`);
      }
    }
  }
</script>

<style scoped="scoped">
  .boxx {
    width: 100%;
    height: 100%;
    border-radius: 4px;
    background-color: #FFFFFF;
    padding: 4px;
  }

  .top {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
  }

  h1 {
    font-size: 18px;
    margin: 0;
  }

  .pagin {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 5px;
  }
  .bar{
    width: 100%;
    height: 90%;
  }
  .czbar {
    width: 100%;
    height: 100%;
    border-radius: 4px;
    background-color: #FFFFFF;
  }
</style>
