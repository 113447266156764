<template>
  <div class="boxx">
    <div ref="czbar" class="czbar"></div>
  </div>
</template>

<script>
	import {unitEnergy4} from "@/api/operationalanalysis/operationalanalysis"
  export default {
    data() {
      return {}
    },
    mounted() {
      this.first()
    },
    methods: {
      chart(box,namelist,chanzhilist,shuilist) {
        var myChart = this.$echarts.init(box);
        var option;

        var colors = ['#5470C6', '#EE6666'];

        option = {
          color: colors,
          title: {
            text: '单位能耗增加值',
            textStyle: {
              color: "#333333",
              fontSize: 18,
            }
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross'
            }
          },
          grid: {
            right: '20%'
          },
          legend: {
            data: ['增加值'],
            width: "17%",
            right: "1%"
          },
          xAxis: [{
            type: 'category',
            axisTick: {
              alignWithLabel: true
            },
            // data: ['纸包装', '软包装', '无纺布', '文创业', '标牌业', '其他']
            data:namelist,
            axisLabel:{interval: 0,
              formatter:function(val){
              	var strs = val.split(''); //字符串数组
              	var str = ''
              	for(var i = 0, s; s = strs[i++];) { //遍历字符串数组
              		str += s;
              		if(!(i % 4)) str += '\n'; //按需要求余
              	}
              	return str
              }
            },

          }],
          yAxis: [
            {
              position: 'left',
              axisLine: {
                show: true,
                lineStyle: {
                  color: colors[0]
                }
              },
              // axisLabel: {
              //   formatter: '{value} 万元'
              // },
              name: "单位:万元/吨标煤",
              splitLine: {
                show: false
              }
            }
          ],
          series: [{
              name: '增加值',
              type: 'bar',
              // data: [270, 350, 410, 260, 400, 380],
              data:chanzhilist,
              barWidth: 17,
              label: {
                show: true,
                position: "top"
              },
              itemStyle: {
                normal: {
                  color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                      offset: 0,
                      color: 'rgba(19,194,194,0.75)'
                    },
                    {
                      offset: 0.25,
                      color: 'rgba(25,168,203,0.67)'
                    },
                    {
                      offset: 0.5,
                      color: 'rgba(33,137,214,0.57)'
                    },
                    {
                      offset: 0.75,
                      color: 'rgba(41,160,226,0.47)'
                    },
                    {
                      offset: 1,
                      color: 'rgba(47,84,235,0.40)'
                    }
                  ]),
                }
              },
            }
          ]
        };

        option && myChart.setOption(option);
      },
      first(){
		  unitEnergy4().then(res=>{
          console.log(res)
          if(res.data.data.list){
            var namelist=[]
            var chanzhilist=[]
            var shuilist=[]
            res.data.data.list.forEach(item=>{
              namelist.push(item.industryName)
              chanzhilist.push({name:item.industryName,value:item.unitEnergyAdded.toFixed(2),id:item.industryId})
            })
            var box=this.$refs.czbar
            this.chart(box,namelist,chanzhilist,shuilist)
          }
        })
      },
    }
  }
</script>

<style scoped="scoped" lang="less">
  .boxx {
    width: 100%;
    height: 100%;

    .czbar {
      width: 100%;
      height: 100%;
      border-radius: 4px;
      background-color: #FFFFFF;
    }
  }
</style>
