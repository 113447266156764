<template>
  <div class="boxx">
    <div class="boxxleft">
        <div id="hightpie" class="highpie"></div>
    </div>
    <div class="boxxright">
      <h1>规上企业亩均税收排名</h1>
      <div class="activebox">
          <div class="activeitem" @click="activepie('A')">A</div>
          <div class="activeitem" @click="activepie('B')">B</div>
          <div class="activeitem" @click="activepie('C')">C</div>
          <div class="activeitem" @click="activepie('D')">D</div>
      </div>
      <dv-scroll-ranking-board :config="config" :class=this.activecolor
      	style="width:100%;height:90%;margin-top: 5%;" />
    </div>
  </div>
</template>

<script>
  import Highcharts from 'highcharts/highstock';
  import Highcharts3D from 'highcharts/highcharts-3d';
  Highcharts3D(Highcharts);
  import {muProductionUpPer,muProductionUp} from "@/api/operationalanalysis/operationalanalysis"
  export default {
    data() {
      return {
        data:[
        	{name:'A企业数30',y:10,id:'A'},
        	{name:'B企业数15',y:10,id:'B'},
        	{name:'C企业数20',y:5,id:'C'},
        	{name:'D企业数20',y:12,id:'D'},
        ],
        config: {
        	data: [],
        	unit: "万元/亩"
        },
        lunbopage: 1,
        lunbolimit: 100,
        resultGrade: 'A',
        activecolor: 'activecolorA',
      }
    },
    created() {
    	this.first()
      this.first2()
    },
    mounted() {

    },
    methods:{
        chart(data){
        	var _this=this
        	var chart = Highcharts.chart('hightpie', {
        			title:{
        				text:"规上企业亩均税收占比",
        				align:"left",
        				style:{
        					color:"#333",
        					fontSize:"18px",
                  fontWeight:"700"
        				}
        			},
              subtitle:{
                text:"单位：万元/亩",
                align:"left",
                style:{
                	color:"#333",
                	fontSize:"16px",
                }
              },
        			credits: {
        			          enabled:false
        			},
        			chart: {
        					type: 'pie',
        					options3d: {
        						enabled: true,
        						alpha: 45,
        						beta: 0,
        					},
        					backgroundColor:"rgba(0,0,0,0)"
        			},
        			tooltip: {
        					pointFormat: '{series.name}: <b>{point.y}</b>'
        			},
        			legend:{
        				labelFormatter:function(val){
        					var arr= this.name.split("")
        					var str=[]
        					if(arr.indexOf("企")!=-1){
        						str=arr.slice(0,arr.indexOf("企"))
        					}
        					return str.toString()
        				},
        			},
        			plotOptions: {
        				pie: {
        					allowPointSelect: true,
        					cursor: 'pointer',
        					depth: 50,
        					size:"90%",
        					dataLabels: {
        						enabled: true,
        						useHTML:true,
        						formatter:function (val){
        							var arr= this.point.name.split("")
        							var str=[]
        							if(arr.indexOf("企")!=-1){
        								str=arr.slice(arr.indexOf("企"),arr.length)
        							}
        							var a=str.join("")
        							var str2=a+"<br>亩均税收:"+this.y
        							return str2
        						}
        					},
        					showInLegend: true,
        				}
        			},
        			series: [{
        					type: 'pie',
        					name: '亩均税收',
        					colors:["#4df29b","#ffd20d","#5f7bd4","#18b6da"],
        					data:data,
        					events: {
        						click: function (event) {
        							// console.log(event.point.options)
                      _this.activepie(event.point.options.id)
        						}
        					}
        			}]
        	});
        },
        first(){
			muProductionUpPer().then(res=>{
        		if(res.data.data.list){
        			var arr=[]
        			res.data.data.list.forEach(item=>{
        				item.muRevenue=item.muRevenue.toFixed(2)*1
        				arr.push({name:item.resultGrade+'企业数:'+item.enterprisesCount,y:item.muRevenue,id:item.resultGrade})
        			})
        			this.data=arr
        			this.chart(this.data)
        		}
        	})

        },
        first2(){
          var _this = this
			muProductionUp(_this.lunbopage,_this.lunbolimit,_this.resultGrade).then(res => {
          	if (res.data.data.list) {
          		var arr = []
          		res.data.data.list.forEach(item => {
          			arr.push({
          				name: item.tenantName,
          				value: item.muRevenue
          			})
          		})
          		_this.config = {
          			data: arr,
          			unit: "万元/亩",
          			rowNum: 5
          		}
          	}
          })
        },
        activepie(val){
          this.resultGrade=val
          this.first2()
          if (this.resultGrade == 'A') {
          	this.activecolor = "activecolorA"
          } else if (this.resultGrade == 'B') {
          	this.activecolor = "activecolorB"
          } else if (this.resultGrade == 'C') {
          	this.activecolor = "activecolorC"
          } else if (this.resultGrade == 'D') {
          	this.activecolor = "activecolorD"
          }
        },
    }
  }
</script>

<style scoped="scoped" lang="less">
  .boxx{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 4px;
    .boxxleft,.boxxright{
      width: 50%;
      height: 100%;
      .highpie{
        width: 100%;
        height: 100%;
      }
    }
    .boxxright{
      position: relative;
      .activebox{
        position: absolute;
        right: 0;
        top: 0;
        width: 50%;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .activeitem{
          width: 40px;
          height: 20px;
          background-color: #4df29b;
          text-align: center;
          border-radius: 4px;
        }
        .activeitem:nth-child(2){
          background-color: #ffd20d;
        }
        .activeitem:nth-child(3){
          background-color: #18b6da;
        }
        .activeitem:nth-child(4){
          background-color: #5f7bd4;
        }
        .activeitem:hover{
          cursor: pointer;
        }
      }
    }
    .boxxright/deep/.info-name{
      color: #333;
      margin-left: 5px;
    }
    .boxxright/deep/.ranking-value{
       color: #333;
    }
    ::v-deep .activecolorA .ranking-column .inside-column {
    	background-color: #4df29b !important;
    }

    ::v-deep .activecolorB .ranking-column .inside-column {
    	background-color: #ffd20d !important;
    }

    ::v-deep .activecolorC .ranking-column .inside-column {
    	background-color: #18b6da !important;
    }

    ::v-deep .activecolorD .ranking-column .inside-column {
    	background-color: #5f7bd4 !important;
    }
    border-radius: 4px;
    background-color: #FFFFFF;
  }
</style>
