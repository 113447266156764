import fetch from "@/api/fetch"

export function ratio(){
	return fetch({
		url:'/apg/analysis/ratio',
		method:'get',
	})
}
// energy
export function unitEnergy(type,limit,page){
	return fetch({
		url:'/apg/analysis/unitEnergy?type='+type+'&limit='+limit+'&page='+page,
		method:'get',
	})
}
export function unitEnergy4(){
	return fetch({
		url:'/apg/analysis/unitEnergy?type=4',
		method:'get',
	})
}
// opera  >first
export function muProductionUpPer(){
	return fetch({
		url:'/apg/analysis/muProductionUpPer',
		method:'get',
	})
}

export function muProductionUp(page,limit,resultGrade){
	return fetch({
		url:'/apg/analysis/muProductionUp?page=' + page + '&limit=' + limit + '&resultGrade=' + resultGrade ,
		method:'get',
	})
}

