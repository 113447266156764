import { render, staticRenderFns } from "./operationbox.vue?vue&type=template&id=50dd7aef&scoped=true&"
import script from "./operationbox.vue?vue&type=script&lang=js&"
export * from "./operationbox.vue?vue&type=script&lang=js&"
import style0 from "./operationbox.vue?vue&type=style&index=0&id=50dd7aef&scoped=scoped&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "50dd7aef",
  null
  
)

export default component.exports